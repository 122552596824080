import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import { useRef } from 'react'
import emailjs from '@emailjs/browser'
import AnimatedLetters from '../AnimatedLetters'
import img1 from '../../assets/images/contact-image.svg'
import './index.scss'

const Contact = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const [isDisabled, setIsDisabled] = useState(false);

  const form = useRef()

  useEffect(() => {
        
    let timeoutId = setTimeout(() => {
        setLetterClass('text-animate-hover')
    }, 5000)
    
    return () => clearTimeout(timeoutId)
}, [])
  const sendEmail = (e) => {
    e.preventDefault()
    setIsDisabled(true);
    emailjs
      .sendForm(process.env.REACT_APP_EMAIL_SERVICEID,process.env.REACT_APP_EMAIL_TAMPLATEID , form.current,process.env.REACT_APP_EMAIL_PUBLICID)
      .then(
        () => {
          alert('Message successfully sent!')
          window.location.reload(false)
        },
        () => {
          alert('Failed to send the message, please try again')
        }
        
      )
      
  }

  return (
    <>
    <div className='logo-container1'>
      <img src={img1} alt='contact'/>
    </div>
      <div className="container contact-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'm', 'e']}
              idx={15}
            />
          </h1>
          <p>
            I am interested in freelance opportunities - especially on ambitious
            or large projects. However, if you have any other requests or
            questions, don't hesitate to contact me using the form below either.
          </p>
          <div className="contact-form">
            <form ref={form} onSubmit={sendEmail}>
              <ul>
                <li className="half">
                  <input placeholder="Name" type="text" name="name" required />
                </li>
                <li className="half">
                  <input
                    placeholder="Email"
                    type="email"
                    name="email"
                    required
                  />
                </li>
                <li>
                  <input
                    placeholder="Subject"
                    type="text"
                    name="subject"
                    required
                  />
                </li>
                <li>
                  <textarea
                    placeholder="Message"
                    name="message"
                    required
                  ></textarea>
                </li>
                { !isDisabled ? ( 
                <li>
                  <input disabled={isDisabled} type="submit" className="flat-button" value="SEND" />
                </li>
                ) : 
                (
                <li>
                  <input disabled={!isDisabled} type="submit" className="flat-button" value="Sending..." />
                </li> 
                )}
               
              </ul>
            </form>
          </div>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default Contact
