
import headerImg from '../../../assets/images/header-img.svg'
import './index.scss'

const Logo = () => {

  return (
    <div className="logo-container">
       <img src={headerImg} alt="Header Img"/>

      
    </div>
  )
}

export default Logo